import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import useFetchBrandsQuery from "../../hooks/useFetchBrandsQuery";
import { setTitle, createMetaTag } from "../common/utils";
import { Loading } from "../common/Loading";
import ReactGA from "react-ga4";

const StyledContainer = styled.div`
  margin-top: 12px;
  background-color: #fff;
  padding: 20px;
`;

const Title = styled.h2`
  font-weight: 700;
`;

const AlphabetRow = styled.div`
  display: flex;
  margin-bottom: 20px;
  margin-top: 20px;
  color: #ff2d55;
  font-size: 15px;
  font-weight: 700;

  & > span {
    margin-right: 15px;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
    & > span {
      margin-right: 15px;
      line-height: 30px;
    }
  }
`;

const AlphabetHeader = styled.h3`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const StyledList = styled.ul`
  padding-left: 0;
`;

const StyledListItem = styled.li`
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 5px;
  list-style-type: none;
  cursor: pointer;
`;

const StyledLink = styled(Link)`
  color: #ff2d55;
  text-decoration: none;
`;

const ScrollTopButton = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #ff2d55;
  color: white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 20px;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
`;

const AlphabetNav = ({ onScrollTo }) => (
  <AlphabetRow>
    {"ABCDEFGHIJKLMNOPQRSTUVWXYZ#".split("").map((letter) => (
      <span key={letter} onClick={() => onScrollTo(letter)}>
        {letter}
      </span>
    ))}
  </AlphabetRow>
);

const BrandsSection = React.forwardRef(({ letter, children }, ref) => (
  <div ref={ref}>
    <AlphabetHeader>{letter}</AlphabetHeader>
    {children}
  </div>
));

const BrandList = () => {
  const [uniqueAlphabets, setUniqueAlphabets] = useState([]);
  const [brands, setBrands] = useState([]);
  const sectionsRef = useRef({});

  const { data: { data: brandsData } = { brandsData: [] }, isLoading } =
    useFetchBrandsQuery();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Brand list page",
    });
    setTitle(`Iris | reviews, routines, and more.`);
    const description = `Iris | All Brands - Iris is a beauty community with non-sponsored reviews, routines, Q&As, Giveaways, and Drops`;
    createMetaTag({
      name: "description",
      content: description,
    });
  }, []);

  useEffect(() => {
    if (brandsData) {
      const uniqueAlphabets = [
        ...new Set(
          brandsData
            .map((brand) => brand?.name.charAt(0).toUpperCase())
            .filter((char) => /^[A-Z]$/.test(char))
        ),
      ];
      setUniqueAlphabets(uniqueAlphabets);
      setBrands(brandsData);
    }
  }, [brandsData]);

  const handleScrollTo = (letter) => {
    const ref = sectionsRef.current[letter];
    if (ref) {
      ref.scrollIntoView({ behavior: "smooth" });
    }
  };

  if (isLoading && brands.length === 0) {
    return <Loading>Loading...</Loading>;
  }

  return (
    <StyledContainer>
      <Title>Brands</Title>
      <AlphabetNav onScrollTo={handleScrollTo} />
      {uniqueAlphabets.map((alphabet) => (
        <div key={alphabet}>
          <BrandsSection
            ref={(el) => (sectionsRef.current[alphabet] = el)}
            letter={alphabet}
          >
            <StyledList>
              {brands
                .filter((brand) =>
                  brand?.name?.toLowerCase().startsWith(alphabet.toLowerCase())
                )
                .map((brand) => (
                  <StyledListItem key={brand?.id}>
                    <StyledLink to={`/brand/${brand?.id}/user/5`}>
                      {brand?.name}
                    </StyledLink>
                  </StyledListItem>
                ))}
            </StyledList>
          </BrandsSection>
        </div>
      ))}

      {/* Non-Alphabetic Section */}
      <BrandsSection ref={(el) => (sectionsRef.current["#"] = el)} letter="#">
        <StyledList>
          {brands
            .filter((brand) => !/^[A-Z]/i.test(brand.name[0]))
            .map((brand) => (
              <StyledListItem key={brand?.id}>
                <StyledLink to={`/brand/${brand?.id}/user/5`}>
                  {brand?.name}
                </StyledLink>
              </StyledListItem>
            ))}
        </StyledList>
      </BrandsSection>
      <ScrollTopButton onClick={() => window.scrollTo(0, 0)}>↑</ScrollTopButton>
    </StyledContainer>
  );
};

export default BrandList;
