const StarRating = ({ rating, isHolyGrail }) => {
  const totalStars = 5;
  const filledStars = Math.round(rating);
  const emptyStars = totalStars - filledStars;

  return (
    <div className="star-rating">
      {Array(filledStars)
        .fill()
        .map((_, index) => (
          <span key={index} className="filled-star">
            ★
          </span>
        ))}
      {Array(emptyStars)
        .fill()
        .map((_, index) => (
          <span key={index} className="empty-star">
            ☆
          </span>
        ))}
      {isHolyGrail ? <span className="holy-grail">Holy Grail</span> : null}
    </div>
  );
};

export default StarRating;
