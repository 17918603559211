import React, { useEffect } from "react";
import styled from "styled-components";
import ReactGA from "react-ga4";
import { Link } from "react-router-dom";
import BrandCard from "./BrandCard";
import useFetchTopHolyGrailsQuery from "../../hooks/useFetchTopHolyGrailsQuery";
import useFetchMostReviewedQuery from "../../hooks/useFetchMostReviewedQuery";
import useFetchRandomBrandsQuery from "../../hooks/useFetchRandomBrandsQuery";
import ProductCardBasic from "../common/ProductCardBasic";
import { setTitle, createMetaTag } from "../common/utils";
import { Loading } from "../common/Loading";
import { APP_URL } from "../../constants/url";

const StyledContainer = styled.div``;

const InnerContainer = styled.div`
  border-radius: 5px;
`;

const ProductContainer = styled.div`
  margin-top: 12px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  padding: 30px;
  text-align: center;

  @media (max-width: 768px) {
    background-color: white;
  }
`;

const WelcomeTitle = styled.h2`
  font-weight: 700;
  margin-bottom: 10px;
`;

const WelcomeSubtitle = styled.h4`
  font-weight: 590;
  font-size: 17pt;

  @media (max-width: 768px) {
    font-size: 15pt;
  }
`;

const WelcomeDescription = styled.div`
  font-weight: 500;
  font-size: 15pt;
  max-width: 580px;
  margin: auto;
  white-space: pre-wrap;

  & > b {
    color: #ff2d55;
  }

  @media (max-width: 768px) {
    font-size: 11pt;
  }
`;

const DownloadButton = styled.a`
  background-color: #ff2d55;
  width: 214px;
  height: 45px;
  color: white;
  border-radius: 12px;
  padding: 10px 20px;
  margin-top: 20px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  text-decoration: none;
  margin-left: auto;
  margin-right: auto;
`;

const StyledSection = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  padding: 20px;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const ReviewsHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 10px;

  & > h3 {
    font-size: 22px;
    font-weight: 700;
  }
`;

const SeeAllButton = styled(Link)`
  color: #ff2d55;
  font-size: 14px;
  cursor: pointer;
  text-align: right;
`;

const ReviewsList = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  overflow-x: auto;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Home = () => {
  const {
    data: { data: topHolyGrails } = { topHolyGrails: [] },
    isLoading: isHolyGrailsLoading,
  } = useFetchTopHolyGrailsQuery({
    current_user_id: 5,
  });

  const {
    data: { data: mostReviewed } = { mostReviewed: [] },
    isLoading: isMostReviewedLoading,
  } = useFetchMostReviewedQuery({
    current_user_id: 5,
  });

  const {
    data: { data: randomBrands } = {
      randomBrands: [],
    },
    isLoading: isBrandsLoading,
  } = useFetchRandomBrandsQuery();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Home page",
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    setTitle(`Iris | reviews, routines, and more.`);
    const description = `Iris is a beauty community with non-sponsored reviews, routines, Q&As, Giveaways, and Drops`;
    createMetaTag({
      name: "description",
      content: description,
    });
  }, []);

  if (isBrandsLoading || isMostReviewedLoading || isHolyGrailsLoading) {
    return <Loading>Loading...</Loading>;
  }

  return (
    <StyledContainer>
      <InnerContainer>
        <ProductContainer>
          <WelcomeTitle>Welcome to Iris</WelcomeTitle>
          <WelcomeSubtitle>Beauty truth be told</WelcomeSubtitle>
          <WelcomeDescription>
            Iris is the best source for{" "}
            <b>
              trusted, non-incentivized and unfiltered reviews and discussions
              in beauty.
            </b>{" "}
            Join Iris today to talk about holy grails, total misses, new
            launches, upcoming sales, and anything in between!
          </WelcomeDescription>
          <DownloadButton href={APP_URL} target="_blank">
            Download Iris
          </DownloadButton>
        </ProductContainer>
        <StyledSection>
          <ReviewsHeader>
            <h3>Most Reviewed Products</h3>
          </ReviewsHeader>
          <ReviewsList>
            {mostReviewed.map((productData) => (
              <ProductCardBasic
                key={`${productData?.id}-most-reviewed-products`}
                productData={productData}
              />
            ))}
          </ReviewsList>
        </StyledSection>
        <StyledSection>
          <ReviewsHeader>
            <h3>Top Holy Grails</h3>
          </ReviewsHeader>
          <ReviewsList>
            {topHolyGrails?.map((productData) => (
              <ProductCardBasic
                key={`${productData?.id}-top-holy-grail-products`}
                productData={productData}
              />
            ))}
          </ReviewsList>
        </StyledSection>
        <StyledSection>
          <ReviewsHeader>
            <h3>Brands</h3>
            <SeeAllButton to={`/brands`}>See All</SeeAllButton>
          </ReviewsHeader>
          <ReviewsList>
            {randomBrands?.map((brand) => (
              <BrandCard key={`${brand?.id}-random-brands`} brand={brand} />
            ))}
          </ReviewsList>
        </StyledSection>
      </InnerContainer>
    </StyledContainer>
  );
};

export default Home;
