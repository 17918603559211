import styled from "styled-components";
import { Link } from "react-router-dom";

export const Breadcrumb = styled.div`
  display: flex;
  font-size: 11px;
  padding: 10px;
  overflow-x: auto;
  white-space: nowrap;
  padding: 5px;

  & > a,
  & > span {
    margin: auto 5px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const HomeLink = styled(Link)`
  color: #ff2d55;
`;

export const BrandLink = styled(Link)`
  color: #ff2d55;
`;

export const ProductLink = styled(Link)`
  color: #ff2d55;
`;

export const ReviewTypeLink = styled.span`
  color: black;
`;

export const CurrentLink = styled.span`
  color: black;
`;
