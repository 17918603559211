import axios from "axios";
import { useQuery } from "react-query";
import { AUTH_TOKEN } from "../constants/url";

const useFetchBrandsInfoQuery = ({ brand_id }) => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const fetchTask = async () => {
    const tasks = await axios.get(
      `${apiUrl}/brands/fetch_summary_by_brand_id?brand_id=${brand_id}`,
      {
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": AUTH_TOKEN,
        },
      }
    );
    console.log("tasks", tasks);
    return tasks?.data;
  };
  const { data, isLoading, refetch } = useQuery("useFetchBrandsInfo", () =>
    fetchTask()
  );
  return {
    data,
    isLoading,
    refetch,
  };
};
export default useFetchBrandsInfoQuery;
