import axios from "axios";
import { useQuery } from "react-query";

const useFetchRoutineQuery = ({ limit }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const fetchTask = async () => {
    const tasks = await axios.get(`${apiUrl}/routines/web?limit=${limit}`);
    return tasks?.data;
  };
  const { data, isLoading, refetch } = useQuery("useFetchRoutineQuery", () =>
    fetchTask()
  );
  return {
    data,
    isLoading,
    refetch,
  };
};
export default useFetchRoutineQuery;
