import { AiFillStar } from "react-icons/ai";
import styled from "styled-components";

const StyledStarRating = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
`;

const StarRating = ({ rating }) => {
  return (
    <StyledStarRating>
      <div>
        {[...Array(5)].map((_, i) => (
          <span key={i}>
            <AiFillStar key={i} color={i < rating ? "#ff2d55" : "#C7C7CC"} />
          </span>
        ))}
      </div>
    </StyledStarRating>
  );
};

export default StarRating;
