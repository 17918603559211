import { useEffect } from "react";
import Card from "react-bootstrap/Card";
import "../../style.css";
// import defaultRoutineImage from "../images/default_routine.svg";
import Image from "react-bootstrap/Image";
import defaultPfp from "../../images/default_pfp.svg";
import ListProducts from "./ListProducts";
import useFetchListsQuery from "../../hooks/useFetchListsQuery";
import useFetchListsByProductQuery from "../../hooks/useFetchListsByProductQuery";
import Loader from "../common/Loader";
import ReactGA from "react-ga4";

const dateAgo = (date) => {
  function convertTimeToAmPm(date) {
    var hours = date?.getHours();
    var minutes = date?.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  const currDate = new Date();
  const yearDifference = currDate?.getFullYear() - date?.getFullYear();
  const monthsAgo =
    yearDifference * 12 + currDate?.getMonth() - date?.getMonth();

  if (monthsAgo === 0) {
    const daysAgo = currDate?.getDate() - date?.getDate();
    if (daysAgo === 0) {
      return convertTimeToAmPm(date);
    } else if (daysAgo === 1) {
      return "1 day ago";
    } else {
      return `${daysAgo} days ago`;
    }
  } else if (monthsAgo === 1) {
    return "1 month ago";
  } else {
    return `${monthsAgo} months ago`;
  }
};

function List() {
  const queryParameters = new URLSearchParams(window.location.search);
  const listId = queryParameters.get("listId");
  const currentUserId = queryParameters.get("currentUserId");

  const { data: lists, isLoading: listsIsLoading } = useFetchListsQuery({
    current_user_id: currentUserId,
    list_id: listId,
  });

  const { data: listsByProduct, isLoading: listsByProductIsLoading } =
    useFetchListsByProductQuery({
      user_id: 18,
      list_id: listId,
    });

  const { data: listSummary } = lists || {};
  const { data: listProducts } = listsByProduct || {};

  const date = new Date(listSummary?.created_at);

  let listUsername = "";
  if (listSummary?.user?.username) {
    listUsername = listSummary?.user?.username;
  } else {
    listUsername = "unknown";
  }

  let productCountStr = undefined;
  if (listSummary?.product_count) {
    productCountStr =
      listSummary?.product_count === 1
        ? `${listSummary?.product_count} product`
        : `${listSummary?.product_count} products`;
  } else {
    productCountStr = "0 products";
  }

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "List page",
    });
  }, []);

  return (
    <Loader isLoading={listsIsLoading || listsByProductIsLoading}>
      <div>
        <Card border="white">
          <div className="list-summary-div">
            <div className="username-box">
              <Image
                style={{ height: "30px", width: "30px" }}
                src={listSummary?.user?.image_url || defaultPfp}
                roundedCircle
              />
              <span className="routine-username">
                {listUsername}{" "}
                <span className="routine-user-not-bold">shared</span> a list
              </span>
              <p className="days-ago">{`${dateAgo(date)}`}</p>
            </div>
            <div className="title">{listSummary?.title}</div>
            <p className="routine-description">{listSummary?.description}</p>
            <div className="routine-step-count">
              {`${productCountStr}`}
              {listSummary?.tags?.map((tag, index) => {
                return <span key={index} className="tag">{` #${tag}`}</span>;
              })}
            </div>
          </div>
        </Card>
        <div>{/* <hr className='horizontal-line'></hr> */}</div>
        <Card style={{ marginTop: "10px" }} border="white">
          <ListProducts
            listProducts={listProducts}
            listUsername={listUsername}
          />
        </Card>
        <hr className="horizontal-line"></hr>
        <div className="like-comment-count-group">
          <div className="like-count-group">
            <img
              className="heart"
              alt="Heart"
              src="https://c.animaapp.com/Ko3Ssjzi/img/heart-1.svg"
            />
            <div className="like-comment-text">
              {listSummary?.like_count || 0}
            </div>
          </div>
          <div className="comment-count-group">
            <img
              className="bubble-left"
              alt="Bubble left"
              src="https://c.animaapp.com/Ko3Ssjzi/img/bubble-left-1.svg"
            />
            <div className="like-comment-text">
              {listSummary?.comment_count || 0}
            </div>
          </div>
        </div>
      </div>
    </Loader>
  );
}

export default List;
