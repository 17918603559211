import styled from "styled-components";
import dp from "../../images/dp_placeholder.png";
import default_image from "../../images/default_routine.png";

const StyledRoutineCard = styled.a`
  text-decoration: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid #d5d5d5;
  border-radius: 5px;
  margin-top: 10px;
  min-width: 350px;
  color: white;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${({ image_url }) => (image_url ? image_url : default_image)}) no-repeat
      center center;

  background-size: cover;
  margin-right: 10px;
`;

const ProfilePicture = styled.div`
  display: flex;
  align-self: baseline;
  margin-top: auto;
  & > img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
  }
`;

const Username = styled.span`
  font-size: 11px;
  font-weight: 600;
  padding-left: 5px;
`;

const RoutineText = styled.div`
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const ReviewCard = ({ routine, user_id }) => {
  return (
    <StyledRoutineCard
      href={`/?routineId=${routine?.id}&currentUserId=${user_id}`}
      image_url={routine?.image_url}
    >
      <RoutineText>{routine?.description}</RoutineText>
      <ProfilePicture>
        <img src={routine?.user?.image_url || dp} alt="no img" />
        <Username>{routine?.user?.username}</Username>
      </ProfilePicture>
    </StyledRoutineCard>
  );
};

export default ReviewCard;
