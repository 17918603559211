import React from "react";
import styled from "styled-components";
import { Outlet } from "react-router-dom";
import Container from "react-bootstrap/Container";
import irisLogo from "../images/iris_logo.png";
import appStoreLogo from "../images/Download_on_the_App_Store_Badge.svg";
import "../index.css";
import "../App.css";

const StyledIcon = styled.a`
  & > img {
    width: 74px;
  }
`;
const MobileLayout = () => {
  return (
    <div>
      <div className="App" style={{ background: "#f2f2f7" }}>
        <Container
          style={{
            width: "394px",
            background: "#FFFFFF",
            padding: "0px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <div className="logo-header">
            <StyledIcon href="/">
              <img
                src={irisLogo}
                style={{ width: "56.34px", height: "29px" }}
                alt="App Store Logo"
              />
            </StyledIcon>
            <a href={process.env.REACT_APP_IRIS_APP_URL}>
              <img src={appStoreLogo} alt="App Store Logo" />
            </a>
          </div>
          <Outlet />
        </Container>
        <Container className="info-footer-container" style={{ padding: "0px" }}>
          <div className="info-footer-download-group">
            <a href={process.env.REACT_APP_IRIS_APP_URL}>
              <img src={appStoreLogo} alt="" />
            </a>
          </div>
          <hr className="horizontal-line"></hr>
          <div className="copyright-info">
            © {new Date().getFullYear()} Iris Labs Inc.
          </div>
          <div className="terms-group">
            <a
              href="https://www.getiris.app/terms-of-service"
              className="terms-link"
              style={{ marginRight: "12px" }}
            >
              Terms
            </a>
            <a
              href="https://www.getiris.app/privacy-policy"
              className="terms-link"
              style={{ marginRight: "10px" }}
            >
              Privacy
            </a>
            <a href="https://www.getiris.app/contact" className="terms-link">
              Contact
            </a>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default MobileLayout;
