import React from "react";
import { Link, Outlet } from "react-router-dom";
import Container from "react-bootstrap/Container";
import irisLogo from "../images/iris_logo.png";
import appStoreLogo from "../images/Download_on_the_App_Store_Badge.svg";
import styled from "styled-components";
import { APP_URL } from "../constants/url";
import "../index.css";
import "../App.css";

const StyledBody = styled.div`
  display: flex;
  background-color: #f2f2f7;
  min-height: 100vh;
  flex-direction: column;

  @media (max-width: 768px) {
    background-color: white;
  }
`;

const StyledContainer = styled(Container)`
  max-width: 900px;
  padding: 0px;
  margin-left: auto;
  margin-right: auto;
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #fff;
`;

const StyledFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const LinksRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #8e8e93;

  & > div {
    padding: 20px 20px;
  }

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

const StyledIcon = styled(Link)`
  & > img {
    width: 74px;
  }
`;

const MainLayout = () => {
  return (
    <div>
      <StyledBody>
        <StyledContainer>
          <StyledHeader>
            <StyledIcon to={"/"}>
              <img src={irisLogo} alt="App Store Logo" />
            </StyledIcon>
            <a href={APP_URL} target="_blank" rel="noreferrer">
              <img src={appStoreLogo} alt="App Store Logo" />
            </a>
          </StyledHeader>
          <Outlet />
        </StyledContainer>
        <StyledFooter>
          <LinksRow>
            <div>© {new Date().getFullYear()} Iris Labs Inc.</div>
            <div>
              <a
                href="https://www.getiris.app/terms-of-service"
                className="terms-link"
                style={{ marginRight: "12px" }}
              >
                Terms
              </a>
              <a
                href="https://www.getiris.app/privacy-policy"
                className="terms-link"
                style={{ marginRight: "10px" }}
              >
                Privacy
              </a>
              <a href="https://www.getiris.app/contact" className="terms-link">
                Contact
              </a>
            </div>
          </LinksRow>
          <div className="info-footer-download-group">
            <a href={APP_URL} target="_blank" rel="noreferrer">
              <img src={appStoreLogo} alt="" />
            </a>
          </div>
        </StyledFooter>
      </StyledBody>
    </div>
  );
};

export default MainLayout;
