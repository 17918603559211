import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledBrandCard = styled(Link)`
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid #d5d5d5;
  border-radius: 2px;
  margin-right: 15px;
  min-width: 180px;
  text-align: center;
  color: black;
`;

const BrandName = styled.div`
  font-size: 12px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const BrandProductCount = styled.div`
  font-size: 10px;
  margin-top: 5px;
  color: #8e8e93;
`;

const BrandCard = ({ brand }) => {
  return (
    <StyledBrandCard to={`/brand/${brand?.id}/user/5`}>
      <BrandName>{brand?.name}</BrandName>
      <BrandProductCount>{brand?.product_count} products</BrandProductCount>
    </StyledBrandCard>
  );
};

export default BrandCard;
