import axios from "axios";
import { useQuery } from "react-query";
import { AUTH_TOKEN } from "../constants/url";

const useFetchRoutinesQuery = ({ current_user_id, routine_id }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const fetchTask = async () => {
    const tasks = await axios.get(
      `${apiUrl}/routines/fetch?current_user_id=${current_user_id}&routine_id=${routine_id}`,
      {
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": AUTH_TOKEN,
        },
      }
    );
    return tasks?.data;
  };
  const { data, isLoading } = useQuery("useFetchRoutines", () => fetchTask());
  return {
    data,
    isLoading,
  };
};
export default useFetchRoutinesQuery;
