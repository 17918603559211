import axios from "axios";
import { useQuery } from "react-query";
import { AUTH_TOKEN } from "../constants/url";

const useFetchListsByProductQuery = ({ user_id, list_id }) => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const fetchTask = async () => {
    const tasks = await axios.get(
      `${apiUrl}/lists/fetch_by_product?user_id=${user_id}&list_id=${list_id}`,
      {
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": AUTH_TOKEN,
        },
      }
    );
    return tasks?.data;
  };
  const { data, isLoading, refetch } = useQuery("useFetchListsByProduct", () =>
    fetchTask()
  );
  return {
    data,
    isLoading,
    refetch,
  };
};
export default useFetchListsByProductQuery;
