import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { AiOutlineShopping } from "react-icons/ai";
import { IoPencilOutline } from "react-icons/io5";
import Link from "../../images/Vector.svg";
import StarRating from "./StarRating";

function ListProducts({ listProducts, listUsername }) {
  const products = [];
  listProducts?.forEach((product, index) => {
    let review = undefined;
    if (product.review) {
      let reviewLink = undefined;
      if (product.review.affiliate_link && !product.review.discount_code) {
        reviewLink = "Use my link";
      }
      if (
        !product.review.affiliate_link &&
        product.review.discount_code &&
        product.review.discount_label
      ) {
        reviewLink = `Use ${product.review.discount_code} for ${product.review.discount_label}`;
      }
      if (
        product.review.affiliate_link &&
        product.review.discount_code &&
        product.review.discount_label
      ) {
        reviewLink = `Use my link and ${product.review.discount_label} for ${product.review.discount_code}`;
      }
      review = (
        <Card.Footer className="product-review">
          <span className="product-review-username">{`${listUsername}'s review: `}</span>
          {product.review?.rating ? (
            <StarRating
              rating={product.review?.rating}
              isHolyGrail={product.review?.is_holy_grail}
            />
          ) : null}
          <span className="product-review-text">{product.review?.text}</span>
          {reviewLink ? (
            <div className="link-div">
              <img className="vector" alt="Vector" src={Link} />
              <div className="text-wrapper">
                <a
                  href={product.review.affiliate_link || null}
                  className="link"
                >
                  {reviewLink}
                </a>
              </div>
            </div>
          ) : null}
        </Card.Footer>
      );
    }

    let reviewCountString = "";
    if (product.review_count) {
      reviewCountString =
        product.review_count === 1
          ? `${product.review_count} Review`
          : `${product.review_count} Reviews`;
    } else {
      reviewCountString = "0 Reviews";
    }

    let holyGrailCountString = "";
    if (product.holy_grail_count) {
      holyGrailCountString =
        product.holy_grail_count === 1
          ? `${product.holy_grail_count} Holy Grail`
          : `${product.holy_grail_count} Holy Grails`;
    } else {
      holyGrailCountString = "0 Holy Grails";
    }

    let productVariant = null;
    if (product.product_variant_value) {
      productVariant = "Color: ";
      productVariant += product.product_variant_value;
    }

    products.push(
      // <div key={index} style={{ marginTop: "12px", marginBottom: "12px"}}>
      <div
        key={index}
        className="product-card-container"
        style={{ marginTop: "50px", marginBottom: "12px" }}
      >
        <div className="card-number">{index + 1}</div>
        <Card key={index} className="product-card">
          <Card.Body>
            <div style={{ textAlign: "center" }}>
              <Card.Title className="list-product-info-brand-name">
                {product.brand}
              </Card.Title>
              <Card.Subtitle className="product-info-product-name">
                {product.name}
              </Card.Subtitle>
              {productVariant && (
                <Card.Subtitle className="product-info-variant-name">
                  {" "}
                  {productVariant}{" "}
                </Card.Subtitle>
              )}
              <div
                style={{
                  display: "inline-flex",
                  gap: "12px",
                  marginTop: "10px",
                  justifyContent: "center",
                }}
              >
                <Card.Text className="product-info-review-count">
                  {reviewCountString}
                </Card.Text>
                <Card.Text className="product-info-review-count">
                  {holyGrailCountString}
                </Card.Text>
              </div>
              <div>
                <img src={product.images[0]} className="list-product-image" />
              </div>
              <div
                style={{
                  display: "inline-flex",
                  gap: "12px",
                  marginTop: "15px",
                  justifyContent: "center",
                }}
              >
                <Button
                  className="shopping-button"
                  href="https://apps.apple.com/us/app/iris-beauty-routines-reviews/id1667048124"
                  variant="danger"
                >
                  <AiOutlineShopping />
                </Button>
                <Button
                  className="review-button"
                  href="https://apps.apple.com/us/app/iris-beauty-routines-reviews/id1667048124"
                  variant="danger"
                >
                  <IoPencilOutline style={{ marginRight: "10px" }} />
                  Review
                </Button>
              </div>
            </div>
          </Card.Body>
          {review}
        </Card>
      </div>
    );
    return;
  });

  return (
    <div className="routine-step steps-container">
      {products?.map((product) => {
        return product;
      })}
    </div>
  );
}

export default ListProducts;
