import { AiFillStar } from "react-icons/ai";
import styled from "styled-components";
import { getCountNumber } from "../common/utils";

const StyledRating = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
  min-width: 115px;
`;

const RatingValue = styled.span`
  display: inline-block;
  margin-left: 5px;
  width: 20px;
  text-align: left;
`;

const StartRating = ({ ratings }) => (
  <StyledRating>
    {[...Array(5)].map((_, i) => (
      <div key={`${i}-star-row`}>
        {[...Array(5 - i)].map((_, j) => (
          <span key={`${i}-${j}-star-icon-container`}>
            <AiFillStar key={`${j}-star-icon`} color="#ff2d55" />
          </span>
        ))}
        <RatingValue>{getCountNumber(ratings[i] || 0)}</RatingValue>
      </div>
    ))}
  </StyledRating>
);

export default StartRating;
