import axios from "axios";
import { useQuery } from "react-query";

const useFetchProductReviewsQuery = ({
  product_id,
  limit,
  reviews_type = "all",
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const fetchTask = async () => {
    const tasks = await axios.get(
      `${apiUrl}/reviews/${product_id}/web?limit=${limit}&reviews_type=${reviews_type}`
    );
    return tasks?.data;
  };
  const { data, isLoading, error } = useQuery(
    `useFetchProductReviewsQuery_${reviews_type}`,
    () => fetchTask()
  );
  return {
    data,
    isLoading,
    error,
  };
};
export default useFetchProductReviewsQuery;
