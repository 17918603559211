import "../../style.css";
import List from "../List";
import Routine from "../Routine";
import Home from "../Home";

function RoutineAndList() {
  const queryParameters = new URLSearchParams(window.location.search);
  const routineId = queryParameters.get("routineId");
  const list = queryParameters.get("listId");

  return (
    <div>
      {routineId && <Routine />}
      {list && <List />}
      {!routineId && !list && <Home />}
    </div>
  );
}

export default RoutineAndList;
