import React, { useEffect } from "react";
import styled from "styled-components";
import ReactGA from "react-ga4";
import { useParams, Link } from "react-router-dom";
import StartRating from "./StarRatings";
import ReviewCard from "./ReviewCard";
import DiscountCard from "./DiscountCard";
import RoutineCard from "./RoutineCard";
import ListCard from "./ListCard";
import OtherProductsCard from "./OtherProductsCard";
import useFetchProductQuery from "../../hooks/useFetchProductQuery";
import useFetchProductReviewsQuery from "../../hooks/useFetchProductReviewsQuery";
import useFetchRoutineQuery from "../../hooks/useFetchRoutineQuery";
import useFetchListQuery from "../../hooks/useFetchListQuery";
import {
  getCountNumber,
  getAvg,
  setTitle,
  createMetaTag,
} from "../common/utils";
import { Loading } from "../common/Loading";
import {
  Breadcrumb,
  HomeLink,
  BrandLink,
  CurrentLink,
} from "../common/BreadCrumb";

const StyledContainer = styled.div``;

const InnerContainer = styled.div`
  border-radius: 5px;
`;

const ProductContainer = styled.div`
  background-color: #ffffff;
  padding: 20px;
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
  }
`;

const ProductImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 300px;

  @media (max-width: 768px) {
  }
`;

const OtherProductsTitle = styled.h3`
  margin-top: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  /* &::-webkit-scrollbar {
    display: none;
  } */
`;

const ImageView = styled.div`
  width: 300px;
  max-height: 300px;
  min-height: 300px;
  overflow: scroll;
  border: 1px solid #d5d5d5;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ProductImage = styled.img`
  width: 100%;
`;

const ImageGallary = styled.div`
  margin: 10px;
  width: 300px;
`;

const VariantColor = styled.div``;

const HorizontalImagesList = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
`;

const ImageThumbnail = styled.img`
  width: 50px;
  height: 50px;
  margin: 10px;
  cursor: pointer;
  border: 1px solid #d5d5d5;
`;

const StyledDropdown = styled.select`
  margin-left: 5px;
  border: none;
  border-bottom: 1px solid #d5d5d5;
`;

const StyledOption = styled.option``;

const ProductInfo = styled.div`
  padding: 20px;
`;

const BrandName = styled(Link)`
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: black;
  &:hover {
    color: darkblue;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const ProductName = styled.h2`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const ProductDescription = styled.p`
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 12px;
  font-weight: 400;
  margin-top: 10px;

  @media (max-width: 768px) {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

const ProductIngredients = styled.p`
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 12px;
  font-weight: 400;
  margin-top: 10px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const ReviewStatsContainer = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  padding: 20px;

  @media (max-width: 768px) {
    border-radius: 25px;
    margin: 10px;
    background-color: #f2f2f7;
  }
`;

const StyledSection = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  padding: 20px;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const ReviewStatsColumns = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const StatsColumn1 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: 768px) {
    padding-top: 20px;
  }
`;

const StatsColumn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: 768px) {
    padding-top: 20px;
    border-top: 2px solid white;
  }
`;

const ReviewRatingsColumn = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid #d5d5d5;
  padding: 20px 40px;

  @media (max-width: 768px) {
    border-right: 2px solid white;
  }
`;

const HolyGrails = styled.div`
  text-align: end;
`;

const HolyGrailsText = styled.span`
  color: #ff2d55;
`;

const HolyGrailsValue = styled.span`
  display: inline-block;
  margin-left: 5px;
  width: 20px;
  text-align: left;
`;

const RatingsDataColumn = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid #d5d5d5;
  padding: 20px 60px;
  text-align: center;

  & > div {
    & > div:first-child {
      font-size: 20px;
      font-weight: 700;
    }
    & > div:nth-child(2) {
      font-size: 15px;
      font-weight: 600;
      color: #636366;
    }
    display: flex;
    flex-direction: column;
    padding-top: 10px;
  }

  @media (max-width: 768px) {
    border-right: none;
    /* padding: 20px 30px; */
  }
`;

const SeeAllButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: end;
  color: #ff2d55;
  font-size: 14px;
  cursor: pointer;
  text-align: right;
`;

const StatsDataColumn = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  & > div {
    & > div:first-child {
      text-align: center;
      font-size: 20px;
      font-weight: 700;
    }
    & > div:nth-child(2) {
      font-size: 15px;
      font-weight: 600;
      color: #636366;
    }
    display: flex;
    flex-direction: column;
    padding: 40px;

    @media (max-width: 768px) {
      padding: 20px;
    }
  }
`;

const ReviewsHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
`;

const ReviewsList = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  overflow-x: auto;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Product = () => {
  const [previewImage, setPreviewImage] = React.useState("");
  const [productImages, setProductImages] = React.useState([]);
  const [variantType, setVariantType] = React.useState("");
  const [variantName, setVariantName] = React.useState("");

  const { product_id, user_id } = useParams();
  const {
    data: productData,
    isLoading,
    refetch,
  } = useFetchProductQuery({
    product_id,
    user_id,
  });

  const {
    data: { data: critical_reviews = [] } = { data: [] },
    isLoading: isCriticalReviewsLoading,
  } = useFetchProductReviewsQuery({
    product_id,
    limit: 15,
    reviews_type: "most_critical_reviews",
  });

  const {
    data: { data: favorable_reviews = [] } = { data: [] },
    isLoading: isFavorableReviewsLoading,
  } = useFetchProductReviewsQuery({
    product_id,
    limit: 15,
    reviews_type: "most_favorable_reviews",
  });

  const {
    data: { data: routines = [] } = { data: [] },
    isLoading: isRoutinesLoading,
  } = useFetchRoutineQuery({ limit: 5 });

  const {
    data: { data: lists = [] } = { data: [] },
    isLoading: isListsLoading,
  } = useFetchListQuery({ limit: 5 });

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Product page",
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (productData?.id && productData?.id != product_id) {
      refetch();
    }

    if (productData?.images) {
      setPreviewImage(productData.images[0]);
      setProductImages(productData.images);
    }
    if (productData?.product_variants && productData?.variant_enabled) {
      setVariantType(productData?.product_variants[0]?.type);
      setVariantName(productData?.product_variants[0]?.value);
    }
    if (!productData?.name) {
      return;
    }
    setTitle(
      `Iris | ${productData?.brand?.name}: ${productData?.name} - reviews, routines, and more.`
    );
    const description = `Iris | ${productData?.brand?.name}: ${productData?.name} - Iris is a beauty community with non-sponsored reviews, routines, Q&As, Giveaways, and Drops`;
    createMetaTag({
      name: "description",
      content: description,
    });
  }, [productData, product_id]);

  if (
    isLoading ||
    isCriticalReviewsLoading ||
    isFavorableReviewsLoading ||
    isRoutinesLoading ||
    isListsLoading
  ) {
    return <Loading>Loading...</Loading>;
  }

  if (!productData) {
    return <Loading>Product not found</Loading>;
  }

  return (
    <StyledContainer>
      <Breadcrumb>
        <HomeLink to="/">Home</HomeLink> {">"}
        <BrandLink to={`/brand/${productData?.brand?.id}/user/${user_id}`}>
          {productData?.brand?.name}
        </BrandLink>
        {">"}
        <CurrentLink>{productData?.name}</CurrentLink>
      </Breadcrumb>
      <InnerContainer>
        <ProductContainer>
          <ProductImageContainer>
            <ImageView>
              <ProductImage src={previewImage} alt="img" />
            </ImageView>
            <ImageGallary>
              {productData?.variant_enabled && variantName && (
                <VariantColor>
                  {variantType}:
                  <StyledDropdown
                    onChange={(e) => {
                      const variant_value = e.target.value;
                      if (variant_value === "default") {
                        setPreviewImage(productData.images[0]);
                        setProductImages(productData.images);
                        return;
                      }
                      const product_variant =
                        productData?.product_variants.find(
                          (variant) => variant.value === variant_value
                        );
                      setVariantName(variant_value);
                      setPreviewImage(product_variant.images?.[0]);
                      setProductImages(product_variant?.images || []);
                      setVariantType(product_variant.type);
                    }}
                  >
                    <StyledOption key="default" value="default">
                      default
                    </StyledOption>
                    {productData?.product_variants.map((variant, index) => (
                      <StyledOption key={index} value={variant.value}>
                        {variant.value}
                      </StyledOption>
                    ))}
                  </StyledDropdown>
                </VariantColor>
              )}
              <HorizontalImagesList>
                {productImages.map((image, index) => (
                  <ImageThumbnail
                    key={`${index}-abc`}
                    src={image}
                    alt="img"
                    onClick={() => {
                      setPreviewImage(image);
                    }}
                  />
                ))}
              </HorizontalImagesList>
            </ImageGallary>
          </ProductImageContainer>
          <ProductInfo>
            <BrandName to={`/brand/${productData?.brand?.id}/user/${user_id}`}>
              {productData?.brand?.name}
            </BrandName>
            <ProductName>{productData?.name}</ProductName>
            <ProductDescription>{productData?.description}</ProductDescription>
            <ProductIngredients>
              Ingredients: {productData?.ingredients}
            </ProductIngredients>
          </ProductInfo>
        </ProductContainer>
        <ReviewStatsContainer>
          <SeeAllButton to={`/reviews/${product_id}/user/${user_id}`}>
            See All Reviews
          </SeeAllButton>
          <ReviewStatsColumns>
            <StatsColumn1>
              <ReviewRatingsColumn>
                <StartRating
                  ratings={
                    productData?.rating_counts
                      ? Object.values(productData?.rating_counts).reverse()
                      : { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 }
                  }
                />
                <HolyGrails>
                  <HolyGrailsText>Holy Grails:</HolyGrailsText>
                  <HolyGrailsValue>
                    {getCountNumber(productData?.holy_grail_count)}
                  </HolyGrailsValue>
                </HolyGrails>
              </ReviewRatingsColumn>
              <RatingsDataColumn>
                <div>
                  <div>{getCountNumber(productData?.review_count)}</div>
                  <div>REVIEWS</div>
                </div>
                <div>
                  <div>{getAvg(productData?.rating_counts)}</div>
                  <div>AVG SCORE</div>
                </div>
              </RatingsDataColumn>
            </StatsColumn1>
            <StatsColumn>
              <StatsDataColumn>
                <div>
                  <div>{getCountNumber(productData?.qa_post_count)}</div>
                  <div>Q&As</div>
                </div>
                <div>
                  <div>{getCountNumber(productData?.routines_count)}</div>
                  <div>Routines</div>
                </div>
                <div>
                  <div>{getCountNumber(productData?.lists_count)}</div>
                  <div>Lists</div>
                </div>
              </StatsDataColumn>
            </StatsColumn>
          </ReviewStatsColumns>
        </ReviewStatsContainer>
        {favorable_reviews.length > 0 && (
          <StyledSection>
            <ReviewsHeader>
              <h3>Most Favorable Reviews</h3>
              <SeeAllButton
                to={`/reviews/${product_id}/user/${user_id}?reviews_type=most_favorable_reviews`}
              >
                See All
              </SeeAllButton>
            </ReviewsHeader>
            <ReviewsList>
              {favorable_reviews.map((review) => (
                <ReviewCard
                  key={`${review?.id}-favourable-reviews`}
                  review={review}
                />
              ))}
            </ReviewsList>
          </StyledSection>
        )}
        {critical_reviews.length > 0 && (
          <StyledSection>
            <ReviewsHeader>
              <h3>Most Critical Reviews</h3>
              <SeeAllButton
                to={`/reviews/${product_id}/user/${user_id}?reviews_type=most_critical_reviews`}
              >
                See All
              </SeeAllButton>
            </ReviewsHeader>
            <ReviewsList>
              {critical_reviews.map((review) => (
                <ReviewCard
                  key={`${review?.id}-critical-reviews`}
                  review={review}
                />
              ))}
            </ReviewsList>
          </StyledSection>
        )}
        {favorable_reviews.length > 0 && (
          <StyledSection>
            <ReviewsHeader>
              <h3>Discount Codes</h3>
            </ReviewsHeader>
            <ReviewsList>
              {favorable_reviews.map((review) => (
                <DiscountCard
                  key={`${review?.id}-discount-codes`}
                  review={review}
                />
              ))}
            </ReviewsList>
          </StyledSection>
        )}
        <StyledSection>
          <ReviewsHeader>
            <h3>Routines</h3>
          </ReviewsHeader>
          <ReviewsList>
            {routines.map((routine) => (
              <RoutineCard
                key={routine?.id}
                routine={routine}
                user_id={user_id}
              />
            ))}
          </ReviewsList>
        </StyledSection>
        <StyledSection>
          <ReviewsHeader>
            <h3>Lists</h3>
          </ReviewsHeader>
          <ReviewsList>
            {lists.map((list) => (
              <ListCard key={list?.id} list={list} user_id={user_id} />
            ))}
          </ReviewsList>
        </StyledSection>
        <StyledSection>
          <ReviewsHeader>
            <OtherProductsTitle>
              Other Products By {productData?.brand?.name}
            </OtherProductsTitle>
            <SeeAllButton
              to={`/brand/${productData?.brand?.id}/user/${user_id}`}
            >
              See All
            </SeeAllButton>
          </ReviewsHeader>
          <ReviewsList>
            {productData?.other_products?.map((product) => (
              <OtherProductsCard key={product.id} productData={product} />
            ))}
          </ReviewsList>
        </StyledSection>
      </InnerContainer>
    </StyledContainer>
  );
};

export default Product;
