import styled from "styled-components";
import { Link } from "react-router-dom";
import dp from "../../images/dp_placeholder.png";

const StyledDiscountCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid #d5d5d5;
  border-radius: 10px;
  margin-top: 10px;
  min-width: 250px;
  color: white;
  background: linear-gradient(to top, #af52de, #5856d6);
  margin-right: 10px;
`;

const LinkText = styled.div`
  font-size: 12px;
`;

const CodeText = styled.div`
  font-size: 12px;
  font-weight: 600;
`;

const ProfileRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`;

const ProfilePicture = styled.div`
  & > img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-top: 5px;
  }
`;

const ProfileData = styled.div`
  display: flex;
  flex-direction: column;
`;

const Username = styled.span`
  font-size: 10px;
  font-weight: 600;
  padding-left: 5px;
`;

const StyledLink = styled(Link)`
  color: white;
  text-decoration: underline !important;
  font-weight: 600;
`;

const DiscountCard = ({ review }) => {
  return (
    <StyledDiscountCard>
      <LinkText>
        Used this{" "}
        <StyledLink to={review?.affiliate_link_url} target="_blank">
          link
        </StyledLink>{" "}
        and
      </LinkText>
      <CodeText>
        {review?.discount_code} for {review?.discount_label}
      </CodeText>
      <ProfileRow>
        <ProfilePicture>
          <img src={review?.user?.image_url || dp} alt="Profile Picture" />
        </ProfilePicture>
        <ProfileData>
          <Username>{review?.user?.username}</Username>
          <Username>
            {new Date(review?.created_at).toLocaleDateString("en-US", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })}
          </Username>
        </ProfileData>
      </ProfileRow>
    </StyledDiscountCard>
  );
};

export default DiscountCard;
