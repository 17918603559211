const Loader = ({ isLoading, children }) => {
  return isLoading ? (
    <div>
      <h1 className="loading-text">Loading...</h1>
    </div>
  ) : (
    children
  );
};

export default Loader;
