import styled from "styled-components";
import StarRating from "../Product/StarRating";
import dp from "../../images/dp_placeholder.png";

const StyledReviewCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid #d5d5d5;
  border-radius: 5px;
  margin-top: 10px;
  background-color: white;

  @media (max-width: 768px) {
    min-width: 100%;
  }
`;

const CardHeader = styled.div`
  margin-bottom: 5px;
`;

const ProfilePicture = styled.div`
  & > img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
  }
`;

const Username = styled.span`
  font-size: 11px;
  font-weight: 600;
  padding-left: 5px;
`;

const CardBBody = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
`;

const RatingRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const HolyGrails = styled.span`
  color: #636366;
  padding-left: 15px;
  padding-top: 3px;
  font-weight: 700;
  font-size: 12px;
`;

const Repurchased = styled.span`
  color: #636366;
  padding-left: 15px;
  padding-top: 3px;
  font-weight: 700;
  font-size: 12px;
`;

const ReviewText = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const ReviewImages = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  overflow: hidden;
`;

const ReviewImage = styled.img`
  width: 40px;
  height: 40px;
  margin-left: 12px;
`;

const ColorVariant = styled.div`
  font-size: 12px;
  color: #636366;
`;

const ReviewCard = ({ review, productVariants, isVariantEnabled }) => {
  const productVariant = productVariants.find(
    (variant) => variant.id === review?.product_variant_id
  );
  return (
    <StyledReviewCard>
      <CardHeader>
        <ProfilePicture>
          <img src={review?.user?.image_url || dp} alt="no img" />
          <Username>{review?.user?.username}</Username>
        </ProfilePicture>
        <RatingRow>
          <StarRating rating={review?.ratings || 0} />
          {review?.is_holy_grail && <HolyGrails>HG</HolyGrails>}
          {review?.is_repurchased && <Repurchased>Repurchased</Repurchased>}
        </RatingRow>
        {isVariantEnabled && (
          <ColorVariant>
            {productVariant?.type}: {productVariant?.value}
          </ColorVariant>
        )}
      </CardHeader>
      <CardBBody>
        <ReviewText>{review?.text}</ReviewText>
        <ReviewImages>
          {review?.images?.map((image, index) => (
            <ReviewImage key={index} src={image} alt="no img" />
          ))}
        </ReviewImages>
      </CardBBody>
    </StyledReviewCard>
  );
};

export default ReviewCard;
