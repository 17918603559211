import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { AiOutlineShopping } from "react-icons/ai";
import { IoPencilOutline } from "react-icons/io5";
import { useState } from "react";
import Link from "../../images/Vector.svg";
import StarRating from "../List/StarRating";

function RoutineSteps({ routineSteps, routineUsername }) {
  const [firstStepHeight, setFirstStepHeight] = useState(0);
  const [lastStepHeight, setLastStepHeight] = useState(0);

  const steps = [];

  routineSteps?.map((step, index) => {
    const isLastStep = index === routineSteps.length - 1;
    const isFirstStep = index === 0;

    const products = [];
    if (!step) {
      return;
    }
    step.products?.map((product, index) => {
      let review = undefined;
      if (product.review) {
        let reviewLink = undefined;
        if (product.review.affiliate_link && !product.review.discount_code) {
          reviewLink = "Use my link";
        }
        if (
          !product.review.affiliate_link &&
          product.review.discount_code &&
          product.review.discount_label
        ) {
          reviewLink = `Use ${product.review.discount_code} for ${product.review.discount_label}`;
        }
        if (
          product.review.affiliate_link &&
          product.review.discount_code &&
          product.review.discount_label
        ) {
          reviewLink = `Use my link and ${product.review.discount_label} for ${product.review.discount_code}`;
        }
        review = (
          <Card.Footer className="product-review">
            <span className="product-review-username">{`${routineUsername}'s review: `}</span>
            {product.review?.rating ? (
              <StarRating
                rating={product.review?.rating}
                isHolyGrail={product.review?.is_holy_grail}
              />
            ) : null}
            <span className="product-review-text">{product.review?.text}</span>
            {reviewLink ? (
              <div class="link-div">
                <img class="vector" alt="Vector" src={Link} />
                <div class="text-wrapper">
                  <a href={product.review.affiliate_link || null} class="link">
                    {reviewLink}
                  </a>
                </div>
              </div>
            ) : null}
          </Card.Footer>
        );
      }

      let reviewCountString = "";
      if (product.review_count) {
        reviewCountString =
          product.review_count === 1
            ? `${product.review_count} Review`
            : `${product.review_count} Reviews`;
      } else {
        reviewCountString = "0 Reviews";
      }
      let variantString = null;
      if (product.product_variant_value) {
        variantString = "Color: ";
        variantString = variantString.concat(product.product_variant_value);
      }

      products.push(
        <div
          key={index}
          style={{
            paddingLeft: "24px",
            marginTop: "12px",
            marginBottom: "12px",
          }}
        >
          <Card key={index} className="product-card">
            <Card.Body>
              <div className="product-view-group">
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    gap: "12px",
                  }}
                >
                  <img src={product.main_images} className="product-image" />
                  <div>
                    <Card.Title className="product-info-brand-name">
                      {product.brand_name}
                    </Card.Title>
                    <Card.Subtitle className="product-info-product-name">
                      {product.name}
                    </Card.Subtitle>
                    {variantString && (
                      <Card.Subtitle className="product-info-variant-name">
                        {variantString}
                      </Card.Subtitle>
                    )}
                    <Card.Text className="product-info-review-count">
                      {reviewCountString}
                    </Card.Text>
                    <Button
                      className="shopping-button"
                      href="https://apps.apple.com/us/app/iris-beauty-routines-reviews/id1667048124"
                      variant="danger"
                    >
                      <AiOutlineShopping />
                    </Button>
                    <Button
                      className="review-button"
                      href="https://apps.apple.com/us/app/iris-beauty-routines-reviews/id1667048124"
                      variant="danger"
                    >
                      <IoPencilOutline style={{ marginRight: "10px" }} />
                      Review
                    </Button>
                  </div>
                </div>
              </div>
            </Card.Body>
            {review}
          </Card>
        </div>
      );
      return;
    });
    steps.push(
      <div key={index} className="step-parent">
        <div className="step-frame">
          {isFirstStep && (
            <div
              className="vertical-line"
              style={{
                height: `calc(${lastStepHeight}px - ${firstStepHeight}px)`,
              }}
            ></div>
          )}
          <div
            ref={(el) => {
              if (!el) {
                return;
              }
              if (isFirstStep) {
                setFirstStepHeight(el.getBoundingClientRect().y);
              }
              if (isLastStep) {
                setLastStepHeight(el.getBoundingClientRect().y);
              }
            }}
            className="ellipse"
          ></div>
          <h4 className="step-number">Step {index + 1}</h4>
        </div>
        <div className="routine-step-description">{step.description}</div>
        {products?.map((product) => {
          return product;
        })}
      </div>
    );
  });

  return (
    <div className="routine-step steps-container">
      <div className="routine-steps-title">Routine Steps</div>
      {steps?.map((step) => {
        return step;
      })}
    </div>
  );
}

export default RoutineSteps;
