import axios from "axios";
import { useQuery } from "react-query";

const useFetchProductQuery = ({ product_id, user_id }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const fetchTask = async () => {
    const tasks = await axios
      .get(`${apiUrl}/products/${product_id}/user/${user_id}`)
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
    return tasks?.data;
  };
  const { data, isLoading, error, refetch } = useQuery(
    "useFetchProductQuery",
    () => fetchTask()
  );
  return {
    data,
    isLoading,
    error,
    refetch,
  };
};
export default useFetchProductQuery;
