import React, { useEffect } from "react";
import Card from "react-bootstrap/Card";
import RoutineSteps from "./RoutineSteps";
import "../../style.css";
import defaultRoutineImage from "../../images/default_routine.svg";
import Image from "react-bootstrap/Image";
import defaultPfp from "../../images/default_pfp.svg";
import useFetchRoutinesQuery from "../../hooks/useFetchRoutinesQuery";
import Loader from "../common/Loader";
import ReactGA from "react-ga4";

const dateAgo = (date) => {
  function convertTimeToAmPm(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  const currDate = new Date();
  const yearDifference = currDate.getFullYear() - date.getFullYear();
  const monthsAgo = yearDifference * 12 + currDate.getMonth() - date.getMonth();

  if (monthsAgo === 0) {
    const daysAgo = currDate.getDate() - date.getDate();
    if (daysAgo === 0) {
      return convertTimeToAmPm(date);
    } else if (daysAgo === 1) {
      return "1 day ago";
    } else {
      return `${daysAgo} days ago`;
    }
  } else if (monthsAgo === 1) {
    return "1 month ago";
  } else {
    return `${monthsAgo} months ago`;
  }
};

function Routine() {
  const queryParameters = new URLSearchParams(window.location.search);
  const routineId = queryParameters.get("routineId");
  const currentUserId = queryParameters.get("currentUserId");

  const { data, isLoading } = useFetchRoutinesQuery({
    routine_id: routineId,
    current_user_id: currentUserId,
  });

  const { data: summary } = data || {};

  console.log("data: " + JSON.stringify(data));

  console.log("Summary: ", summary);
  const date = new Date(summary?.created_at);

  let routineUsername = "";
  if (summary?.user?.username) {
    routineUsername = summary?.user?.username;
  } else {
    routineUsername = "unknown";
  }

  let imageUrl = defaultRoutineImage;
  if (summary?.image_url) {
    imageUrl = summary?.image_url;
  }

  let stepCountStr = undefined;
  if (summary?.step_count) {
    stepCountStr =
      summary?.step_count === 1
        ? `${summary?.step_count} step`
        : `${summary?.step_count} steps`;
  } else {
    stepCountStr = "0 steps";
  }

  let productCountStr = undefined;
  if (summary?.product_count) {
    productCountStr =
      summary?.product_count === 1
        ? `${summary?.product_count} product`
        : `${summary?.product_count} products`;
  } else {
    productCountStr = "0 products";
  }

  let imageHeader = (
    <Card.Img variant="top" className="cardImage" src={imageUrl} />
  );

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Routine page",
    });
  }, []);

  return (
    <Loader isLoading={isLoading}>
      <div>
        <Card border="white">
          {imageHeader}
          <div className="summary-div">
            <div className="summary-category-text">
              {summary?.category} Routine
            </div>
            <div className="title">{summary?.title}</div>
            <div className="username-box">
              <Image
                style={{ height: "30px", width: "30px" }}
                src={summary?.user?.image_url || defaultPfp}
                roundedCircle
              />
              <span className="routine-username">{routineUsername}</span>
              <span className="days-ago">{`${dateAgo(date)}`}</span>
            </div>
            <p className="routine-description">{summary?.description}</p>
            <div className="routine-step-count">{`${stepCountStr}, ${productCountStr}`}</div>
          </div>
        </Card>
        <div>
          <hr className="horizontal-line"></hr>
        </div>
        <Card className="routine-steps" border="white">
          <RoutineSteps
            routineSteps={summary?.steps}
            routineUsername={routineUsername}
          />
        </Card>
        <hr className="horizontal-line"></hr>
        <div className="like-comment-count-group">
          <div className="like-count-group">
            <img
              className="heart"
              alt="Heart"
              src="https://c.animaapp.com/Ko3Ssjzi/img/heart-1.svg"
            />
            <div className="like-comment-text">{summary?.like_count || 0}</div>
          </div>
          <div className="comment-count-group">
            <img
              className="bubble-left"
              alt="Bubble left"
              src="https://c.animaapp.com/Ko3Ssjzi/img/bubble-left-1.svg"
            />
            <div className="like-comment-text">
              {summary?.comment_count || 0}
            </div>
          </div>
        </div>
      </div>
    </Loader>
  );
}

export default Routine;
