import styled from "styled-components";
import { Link } from "react-router-dom";
import { AiOutlineShopping } from "react-icons/ai";
import { APP_URL } from "../../constants/url";

const StyledProductCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  text-align: center;
  border: 1px solid #d5d5d5;
  border-radius: 5px;
  margin-top: 10px;
  margin-right: 10px;
  background-color: white;
  min-width: 180px;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const ReviewCount = styled.div`
  font-size: 11px;

  @media (max-width: 768px) {
    font-size: 8px;
  }
`;

const BrandName = styled(Link)`
  font-size: 14px;
  font-weight: 600;
  color: black;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    color: darkblue;
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const ProductName = styled(Link)`
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 5px;
  color: black;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  &:hover {
    color: darkblue;
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const ProductPicture = styled(Link)`
  cursor: pointer;

  & > img {
    width: 100px;
    min-height: 100px;
  }
`;

const FooterSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 10px;
`;

const ShoppingButton = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  border: 1px solid #ff2d55;
  font-size: 20px;
  border-radius: 8px;
  cursor: pointer;
`;

const ReviewsButton = styled.a`
  background-color: #ff2d55;
  color: white;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  border-radius: 8px;
`;

const ProductCardBasic = ({ productData, user_id = 5 }) => {
  return (
    <StyledProductCard>
      <BrandName to={`/brand/${productData?.brand_id}/user/${user_id}`}>
        {productData?.brand_name}
      </BrandName>
      <ProductName to={`/product/${productData?.id}/user/${user_id}`}>
        {productData?.name}
      </ProductName>
      <ReviewCount>{productData?.review_count} Reviews</ReviewCount>
      <ProductPicture to={`/product/${productData?.id}/user/${user_id}`}>
        <img src={productData.images[0]} alt="no img" />
      </ProductPicture>
      <FooterSection>
        <ShoppingButton href={APP_URL} target="_blank">
          <AiOutlineShopping color="#ff2d55" />
        </ShoppingButton>
        <ReviewsButton href={APP_URL} target="_blank">
          My Reviews
        </ReviewsButton>
      </FooterSection>
    </StyledProductCard>
  );
};

export default ProductCardBasic;
